/**
 * Javascript for InjectorAds
 *
 * Finds target elements inside singular article markup and injects ads.
 **/

function InjectorAds() {
  this.init = () => {
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio && entry.intersectionRatio < 1) {
            if (entry.boundingClientRect.top <= 81) {
              // If intersecting at the top of the browser, remove sticky after 2 seconds.
              setTimeout(() => entry.target.classList.remove("sticky"), 2000);
            } else {
              // If intersecting at the bottom of the browser, add sticky back.
              entry.target.classList.add("sticky");
            }
          }
        });
      },
      {
        rootMargin: `-81px 0px 0px 0px`, // give 1px more than the header height or the element will never intersect
        threshold: [1],
      }
    );
  };
  this.betweenArticle = ($article, ads) => {
    if (typeof ads.between_article === "string" && ads.between_article !== "") {
      // Don't show this ad unit if it's the first article on the page.
      if ($article[0] === document.querySelector("article:first-of-type")) {
        return;
      }
      let widget = ads.between_article;
      $article.prepend(widget.replace("INJECTOR_COUNTER", 1)); // one per article.
      const target = $article.find(".ad-position.infinite_scroll")[0];
      if (target) {
        this.observer.observe(target);
      }
    }
  };
  this.everyThird = (article, ads) => {
    if (typeof ads.mobile === "string") {
      let widget = ads.mobile;
      let every = 3;
      if ($(window).width() >= 768) {
        widget = ads.desktop;
        every = 4; // Increases the space on desktop.
      }

      const mainContent = article.find('[data-js="main-content"]');
      const pCount = mainContent.children("p:not(:empty)").length;
      const galleryCount = mainContent.find(".gallery-slide").length;
      const commerceCount = mainContent.find(".commerce-gallery-item").length;

      let injector_counter = 0;
      // Check Paragraphs first.
      for (let index = every; index < pCount - 2; index++) {
        let this_ad = widget;
        this_ad = this_ad.replace(/INJECTOR_COUNTER/g, injector_counter++);
        mainContent.children("p:eq(" + index + ")").before(this_ad);
        index = index + every - 1;
      }
      for (let index = every; index < galleryCount - 1; index++) {
        const itemSelector = "gallery-slide";
        let this_ad = widget;
        this_ad = this_ad.replace(/INJECTOR_COUNTER/g, injector_counter++);
        mainContent
          .find(`.${itemSelector}:eq( ` + index + ` )`)
          .before(this_ad);
        index = index + every - 1;
      }
      for (let index = every; index < commerceCount - 1; index++) {
        const itemSelector = "commerce-gallery-item";
        let this_ad = widget;
        this_ad = this_ad.replace(/INJECTOR_COUNTER/g, injector_counter++);
        mainContent
          .find(`.${itemSelector}:eq( ` + index + ` )`)
          .before(this_ad);
        index = index + every - 1;
      }
    }
  };
}

export default InjectorAds;
